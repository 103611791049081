import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import {
  FiEdit,
  FiLogOut,
  FiMoon,
  FiPlus,
  FiSun,
  FiTrash2,
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import db from './firebase' // Import your Firebase configuration

const AdminPage = () => {
  const [dealerships, setDealerships] = useState([])
  const [selectedDealership, setSelectedDealership] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { toggleColorMode } = useColorMode()
  const bgColor = useColorModeValue('gray.100', 'gray.900')
  const navigate = useNavigate()

  useEffect(() => {
    fetchDealerships()
  }, [])

  const fetchDealerships = async () => {
    const dealershipsRef = collection(db, 'dealerships')
    const snapshot = await getDocs(dealershipsRef)
    const dealershipsData = snapshot.docs.map((doc) => ({
      username: doc.id,
      ...doc.data(),
    }))
    setDealerships(dealershipsData)
  }

  const handleEdit = (dealership) => {
    setSelectedDealership(dealership)
    onOpen()
  }

  const handleAdd = () => {
    setSelectedDealership(null)
    onOpen()
  }

  const handleDelete = async (username) => {
    try {
      await deleteDoc(doc(db, 'dealerships', username))
      toast({
        title: 'Dealership Deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      fetchDealerships()
    } catch (error) {
      console.error('Error deleting dealership:', error)
      toast({
        title: 'Error Deleting Dealership',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    const { username, ...dealershipData } = data

    if (selectedDealership) {
      // Update existing dealership
      await updateDoc(doc(db, 'dealerships', username), dealershipData)
      toast({
        title: 'Dealership Updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } else {
      // Add new dealership
      await setDoc(doc(db, 'dealerships', username), dealershipData)
      toast({
        title: 'Dealership Added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }

    onClose()
    fetchDealerships()
  }

  const handleLogout = () => {
    navigate('/')
  }

  return (
    <Box bg={bgColor} minH='100vh'>
      <Container maxW='container.xl' py={8}>
        <Flex align='center' mb={8}>
          <Heading as='h1' size='2xl'>
            Dealerships
          </Heading>
          <Spacer />
          <IconButton
            icon={<FiLogOut />}
            aria-label='Logout'
            variant='ghost'
            onClick={handleLogout}
          />
          <IconButton
            icon={useColorModeValue(<FiMoon />, <FiSun />)}
            aria-label='Toggle Color Mode'
            variant='ghost'
            onClick={toggleColorMode}
          />
        </Flex>
        <Box overflowX='auto'>
          <TableContainer>
            <Table variant='simple' colorScheme='blue'>
              <Thead>
                <Tr>
                  <Th>Username</Th>
                  <Th>Name</Th>
                  <Th>Dealer ID</Th>
                  <Th>Location ID</Th>
                  <Th>Active</Th>
                  <Th>Facebook Username</Th>
                  <Th>Facebook Password</Th>
                  <Th>Password</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dealerships.map((dealership) => (
                  <Tr key={dealership.username}>
                    <Td>{dealership.username}</Td>
                    <Td>{dealership.name}</Td>
                    <Td>{dealership.dealer_id}</Td>
                    <Td>{dealership.location_id}</Td>
                    <Td>{dealership.active ? 'Active' : 'Inactive'}</Td>
                    <Td>{dealership.facebook_username}</Td>
                    <Td>{dealership.facebook_password}</Td>
                    <Td>{dealership.password}</Td>
                    <Td>
                      <IconButton
                        icon={<FiEdit />}
                        aria-label='Edit'
                        variant='ghost'
                        onClick={() => handleEdit(dealership)}
                      />
                      <IconButton
                        icon={<FiTrash2 />}
                        aria-label='Delete'
                        variant='ghost'
                        colorScheme='red'
                        onClick={() => handleDelete(dealership.username)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex justify='center' mt={8}>
          <IconButton
            icon={<FiPlus />}
            aria-label='Add Dealership'
            colorScheme='green'
            onClick={handleAdd}
          />
        </Flex>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedDealership ? 'Edit Dealership' : 'Add Dealership'}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl>
                <FormLabel>Username</FormLabel>
                <Input
                  type='text'
                  name='username'
                  defaultValue={selectedDealership?.username}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  name='name'
                  defaultValue={selectedDealership?.name}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Dealer ID</FormLabel>
                <Input
                  type='text'
                  name='dealer_id'
                  defaultValue={selectedDealership?.dealer_id}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Location ID</FormLabel>
                <Input
                  type='text'
                  name='location_id'
                  defaultValue={selectedDealership?.location_id}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Active</FormLabel>
                <Checkbox
                  name='active'
                  defaultChecked={selectedDealership?.active}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Facebook Username</FormLabel>
                <Input
                  type='text'
                  name='facebook_username'
                  defaultValue={selectedDealership?.facebook_username}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Facebook Password</FormLabel>
                <Input
                  type='text'
                  name='facebook_password'
                  defaultValue={selectedDealership?.facebook_password}
                  required
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type='text'
                  name='password'
                  defaultValue={selectedDealership?.password}
                  required
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button type='submit' colorScheme='blue'>
                {selectedDealership ? 'Update' : 'Add'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AdminPage
