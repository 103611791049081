import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import db from './firebase'

const AuthPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast()
  const navigate = useNavigate()
  const { search } = useLocation()

  const showErrorToast = useCallback(
    (title, description) => {
      toast({
        title,
        description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
    [toast]
  )

  const setAuthDataInLocalStorage = useCallback(
    (dealerId, locationId, username) => {
      localStorage.setItem('dealer_id', dealerId)
      localStorage.setItem('location_id', locationId)
      localStorage.setItem('username', username)
    },
    []
  )

  const authenticateUserByDealerAndLocation = useCallback(
    async (dealerId, locationId) => {
      try {
        const dealershipsCollection = collection(db, 'dealerships')
        const q = query(
          dealershipsCollection,
          where('dealer_id', '==', dealerId),
          where('location_id', '==', locationId)
        )
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const dealershipDoc = querySnapshot.docs[0]
          const dealershipData = dealershipDoc.data()
          const username = dealershipDoc.id

          setAuthDataInLocalStorage(
            dealershipData.dealer_id,
            dealershipData.location_id,
            username
          )
          navigate('/vehicles')
        } else {
          showErrorToast(
            'Authentication Failed',
            'Invalid dealer ID or location ID.'
          )
        }
      } catch (error) {
        console.error('Authentication Error:', error)
        showErrorToast(
          'Authentication Error',
          'An unexpected error occurred during the authentication process.'
        )
      }
    },
    [navigate, showErrorToast, setAuthDataInLocalStorage]
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const dealerId = queryParams.get('dealerID')
    const locationId = queryParams.get('locationID')

    if (dealerId && locationId) {
      authenticateUserByDealerAndLocation(dealerId, locationId)
    }
  }, [search, authenticateUserByDealerAndLocation])

  const authenticateUser = useCallback(async () => {
    try {
      const dealershipsCollection = collection(db, 'dealerships')
      const q = query(dealershipsCollection, where('password', '==', password))
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        const dealershipDoc = querySnapshot.docs.find(
          (doc) => doc.id === username
        )

        if (dealershipDoc) {
          const dealershipData = dealershipDoc.data()

          setAuthDataInLocalStorage(
            dealershipData.dealer_id,
            dealershipData.location_id,
            username
          )
          navigate('/')
        } else {
          showErrorToast(
            'Authentication Failed',
            'Invalid username or password.'
          )
        }
      } else {
        showErrorToast('Authentication Failed', 'Invalid username or password.')
      }
    } catch (error) {
      console.error('Login Error:', error)
      showErrorToast(
        'Login Error',
        'An unexpected error occurred during the login process.'
      )
    }
  }, [username, password, navigate, showErrorToast, setAuthDataInLocalStorage])

  const backgroundColor = useColorModeValue('gray.100', 'gray.700')

  return (
    <Flex height='100vh' align='center' justify='center'>
      <Flex
        direction='column'
        background={backgroundColor}
        padding={[4, 8]}
        width={['85%', '70%', '50%', '30%']}
        borderRadius='lg'
        boxShadow='lg'
      >
        <Heading marginBottom={6} textAlign='center'>
          Log In
        </Heading>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor='username'>Username</FormLabel>
            <Input
              id='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type='text'
              placeholder='Enter your username'
              background='white'
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor='password'>Password</FormLabel>
            <Input
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              placeholder='Enter your password'
              background='white'
            />
          </FormControl>
          <Button
            colorScheme='blue'
            onClick={authenticateUser}
            width='full'
            marginTop={4}
          >
            Log In
          </Button>
          <Link to='/reset-password' style={{ marginTop: '10px' }}>
            Reset Password
          </Link>
          <Link to='/admin-auth' style={{ marginTop: '10px' }}>
            Login as Admin
          </Link>
        </VStack>
        <Text fontSize='sm' color='gray.600' alignSelf='center' marginTop={6}>
          © 2024 AutoVizo. All rights reserved.
        </Text>
      </Flex>
    </Flex>
  )
}

export default AuthPage
