import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import db from './firebase' // Make sure this path is correct

const Settings = () => {
  const [fbUsername, setFbUsername] = useState('')
  const [fbPassword, setFbPassword] = useState('')
  const toast = useToast()

  useEffect(() => {
    const fetchDealerData = async () => {
      const storedUsername = localStorage.getItem('username')
      if (storedUsername) {
        const dealerRef = doc(db, 'dealerships', storedUsername)
        try {
          const dealerSnap = await getDoc(dealerRef)
          if (dealerSnap.exists()) {
            const dealerData = dealerSnap.data()
            setFbUsername(dealerData.facebook_username || '')
            setFbPassword(dealerData.facebook_password || '')
          }
        } catch (error) {
          console.error('Error fetching dealer data:', error)
        }
      }
    }

    fetchDealerData()
  }, [])

  const handleSave = async () => {
    const storedUsername = localStorage.getItem('username')
    if (!storedUsername) {
      toast({
        title: 'Error',
        description: 'No dealer username found.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    const dealerRef = doc(db, 'dealerships', storedUsername)

    try {
      await updateDoc(dealerRef, {
        facebook_username: fbUsername,
        facebook_password: fbPassword,
      })

      toast({
        title: 'Settings saved',
        description:
          'Your Facebook credentials have been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      console.error('Error updating settings:', error)
      toast({
        title: 'Error',
        description: 'There was an error saving your settings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Center>
      <VStack spacing={4} w='md' p={6} boxShadow='md' borderRadius='lg'>
        <Box as='h2' textAlign='center' fontSize='xl' mb={4}>
          Please enter your Facebook credentials
        </Box>

        <FormControl id='fbUsername' isRequired>
          <FormLabel>Facebook Username</FormLabel>
          <Input
            type='text'
            value={fbUsername}
            onChange={(e) => setFbUsername(e.target.value)}
          />
        </FormControl>

        <FormControl id='fbPassword' isRequired>
          <FormLabel>Facebook Password</FormLabel>
          <Input
            type='password'
            value={fbPassword}
            onChange={(e) => setFbPassword(e.target.value)}
          />
        </FormControl>

        <Button
          colorScheme='green'
          onClick={handleSave}
          _hover={{ bg: 'green.600' }}
        >
          Save
        </Button>
      </VStack>
    </Center>
  )
}

export default Settings
