import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AuthWrapper = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    // Check for the required localStorage items
    const username = localStorage.getItem('username')
    const dealerId = localStorage.getItem('dealer_id')
    const locationId = localStorage.getItem('location_id')

    // Redirect to login if any of the items are not present
    if (!username || !dealerId || !locationId) {
      navigate('/login')
    }
  }, [navigate]) // Add navigate as a dependency

  // Render children if all items are present
  return children
}

export default AuthWrapper
