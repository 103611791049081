import { Text } from '@chakra-ui/react'
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import AdminAuthPage from './AdminAuthPage'
import AdminPage from './AdminPage'
import AuthPage from './AuthPage'
import Layout from './Layout'
import Settings from './Settings'
import VehiclesTable from './VehiclesTable'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<AuthPage />} />
        <Route path='/admin-auth' element={<AdminAuthPage />} />
        <Route path='/admin-page' element={<AdminPage />} />

        <Route path='/' element={<Layout />}>
          <Route index element={<Text>Welcome to the homepage!</Text>} />
          <Route path='vehicles' element={<VehiclesTable />} />
          <Route path='settings' element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
