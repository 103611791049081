import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const AdminAuthPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const toast = useToast()

  const handleLogin = () => {
    // Hard-coded authentication
    if (username === 'admin' && password === 'password') {
      // Redirect to the admin dashboard or perform any necessary actions
      toast({
        title: 'Login Successful',
        description: 'Welcome, admin!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate('/admin-page')
    } else {
      toast({
        title: 'Login Failed',
        description: 'Invalid username or password',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const bgColor = useColorModeValue('gray.100', 'gray.700')
  const textColor = useColorModeValue('gray.700', 'white')

  return (
    <Box bg={bgColor} minHeight='100vh' py={12}>
      <Container maxW='lg'>
        <VStack spacing={8} align='center'>
          <Icon as={FaLock} boxSize={12} color='blue.500' />
          <Heading as='h1' size='2xl' textAlign='center' color={textColor}>
            Admin Authentication
          </Heading>
          <Box
            bg={useColorModeValue('white', 'gray.800')}
            p={8}
            borderRadius='lg'
            boxShadow='lg'
            width='100%'
          >
            <Stack spacing={6}>
              <FormControl>
                <FormLabel>Username</FormLabel>
                <Input
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  size='lg'
                  variant='filled'
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size='lg'
                  variant='filled'
                />
              </FormControl>
              <Button
                colorScheme='blue'
                size='lg'
                onClick={handleLogin}
                width='100%'
              >
                Login
              </Button>
            </Stack>
          </Box>
        </VStack>
      </Container>
    </Box>
  )
}

export default AdminAuthPage
