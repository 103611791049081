import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import db from './firebase' // Adjust the path as necessary

const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL

const VehiclesTable = () => {
  const [vehicles, setVehicles] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const bgColor = useColorModeValue('white', 'gray.800')
  const textColor = useColorModeValue('gray.800', 'white')

  const fetchInventory = async () => {
    setIsFetching(true)
    try {
      const dealer_id = localStorage.getItem('dealer_id')
      const location_id = localStorage.getItem('location_id')
      const queryParameters = new URLSearchParams({
        dealer_id,
        location_id,
      }).toString()

      const response = await fetch(
        `${baseURL}/update_dealership?${queryParameters}`
      )
      const data = await response.json()
      if (data.message === 'Cron job completed successfully') {
        console.log(data.message)
        window.location.reload() // Optionally refresh the page
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const dealer_id = localStorage.getItem('dealer_id')
        const location_id = localStorage.getItem('location_id')
        const vehiclesCol = collection(db, 'vehicles')
        const q = query(
          vehiclesCol,
          where('dealer_id', '==', dealer_id),
          where('location_id', '==', location_id)
        )

        const vehicleSnapshot = await getDocs(q)
        const vehiclesList = vehicleSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isScheduled: doc.data().schedule_to_post ? true : false,
        }))
        setVehicles(vehiclesList)
      } catch (error) {
        console.error('Error fetching vehicles:', error)
      }
    }

    fetchVehicles()
  }, [])

  const handleSchedule = async (vehicle) => {
    const vehicleRef = doc(db, 'vehicles', vehicle.id)
    await updateDoc(vehicleRef, {
      schedule_to_post: new Date().toISOString(),
    })
    setVehicles(
      vehicles.map((v) =>
        v.id === vehicle.id ? { ...v, isScheduled: true } : v
      )
    )
  }

  const handleCancel = async (vehicle) => {
    const vehicleRef = doc(db, 'vehicles', vehicle.id)
    await updateDoc(vehicleRef, {
      schedule_to_post: '',
    })
    setVehicles(
      vehicles.map((v) =>
        v.id === vehicle.id ? { ...v, isScheduled: false } : v
      )
    )
  }

  const formatLastTimePosted = (time) => {
    if (!time) return 'Not Posted'
    try {
      const date = new Date(time)
      return format(date, 'MMMM dd, yyyy')
    } catch (error) {
      console.error('Error formatting date:', error)
      return 'Invalid Date'
    }
  }

  return (
    <Box bg={bgColor} color={textColor} p={4} borderRadius='md' boxShadow='md'>
      <Alert status='info' mb={4}>
        <AlertIcon />
        <AlertDescription>
          Vehicles scheduled for posting appear on Facebook Marketplace just
          once per day.
        </AlertDescription>
      </Alert>

      <Flex justifyContent='center' mb={4}>
        <Button
          colorScheme={isFetching ? 'gray' : 'green'}
          onClick={!isFetching ? fetchInventory : null}
          isDisabled={isFetching}
          width={isMobile ? '100%' : '20%'}
        >
          {isFetching ? (
            <>
              <Spinner size='sm' mr={3} />
              Fetching...
            </>
          ) : (
            'Fetch Inventory from DMS'
          )}
        </Button>
      </Flex>

      <Box overflowX='auto'>
        <Table variant='simple' size={isMobile ? 'sm' : 'md'}>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Photo</Th>
              <Th>Year</Th>
              <Th>Make</Th>
              <Th>Model</Th>
              {!isMobile && (
                <>
                  <Th>VIN</Th>
                  <Th>Trim</Th>
                  <Th>Last Time Posted</Th>
                </>
              )}
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vehicles.map((vehicle, index) => (
              <React.Fragment key={index}>
                <Tr>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td>
                    <Image
                      src={vehicle.images[0]}
                      alt='Vehicle'
                      boxSize={isMobile ? '60px' : '100px'}
                      objectFit='cover'
                      borderRadius='md'
                    />
                  </Td>
                  <Td>{vehicle.year}</Td>
                  <Td>{vehicle.make}</Td>
                  <Td>{vehicle.model}</Td>
                  {!isMobile && (
                    <>
                      <Td>{vehicle.vin}</Td>
                      <Td>{vehicle.trim}</Td>
                      <Td>{formatLastTimePosted(vehicle.last_time_posted)}</Td>
                    </>
                  )}
                  <Td>
                    <Flex direction={isMobile ? 'column' : 'row'}>
                      <Button
                        colorScheme='blue'
                        onClick={() => handleSchedule(vehicle)}
                        isDisabled={vehicle.isScheduled}
                        mb={isMobile ? 2 : 0}
                        mr={isMobile ? 0 : 2}
                        width={isMobile ? '100%' : 'auto'}
                      >
                        Schedule
                      </Button>
                      <Button
                        colorScheme='red'
                        onClick={() => handleCancel(vehicle)}
                        isDisabled={!vehicle.isScheduled}
                        width={isMobile ? '100%' : 'auto'}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

export default VehiclesTable
