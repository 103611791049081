import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CopilotKit } from '@copilotkit/react-core'
import { CopilotSidebar } from '@copilotkit/react-ui'
import '@copilotkit/react-ui/styles.css'

import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import AuthWrapper from './AuthWrapper'

const Layout = () => {
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.clear()
    navigate('/login')
  }

  return (
    <ChakraProvider>
      <AuthWrapper>
        <Box>
          <Flex
            bg='blue.500'
            color='white'
            padding='4'
            justifyContent='space-between'
          >
            <Heading size='md'>AUTOVIZO</Heading>
            <Box>
              <Text as={Link} to='/' sx={topLinkStyle} mr='1rem'>
                Home
              </Text>
              <Text as={Link} to='/about' sx={topLinkStyle} mr='1rem'>
                About
              </Text>
              <Text as={Link} to='/contact' sx={topLinkStyle}>
                Contact
              </Text>
            </Box>
          </Flex>
          <Flex>
            <VStack
              bg='gray.100'
              width='200px'
              height='calc(100vh - 64px)'
              padding='4'
              spacing='4'
              align='left'
            >
              <Text as={Link} to='/' sx={linkStyle}>
                Dashboard
              </Text>
              <Text as={Link} to='/vehicles' sx={linkStyle}>
                Vehicles
              </Text>
              <Text as={Link} to='/settings' sx={linkStyle}>
                Settings
              </Text>
              <Spacer />
              <Button colorScheme='red' onClick={handleLogout}>
                Logout
              </Button>
            </VStack>
            <Box flex='1' p='6'>
              <CopilotKit url='https://copilotkit-backend-0x11c11e.vercel.app/'>
                <CopilotSidebar>
                  <Outlet />
                </CopilotSidebar>
              </CopilotKit>
            </Box>
          </Flex>
        </Box>
      </AuthWrapper>
    </ChakraProvider>
  )
}

const linkStyle = {
  display: 'block',
  padding: '0.5rem 1rem',
  borderRadius: '0.375rem',
  textDecoration: 'none',
  width: '100%',
  textAlign: 'left',
  color: 'gray.600',
  _hover: { background: 'gray.200', color: 'blue.500' },
}

const topLinkStyle = {
  padding: '0.5rem 1rem',
  borderRadius: '0.375rem',
  textDecoration: 'none',
  color: 'white',
  _hover: { background: 'gray.200', color: 'blue.500' },
}

export default Layout
