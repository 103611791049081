// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCN0Yzc5xm1bo8T3FkAMKK1z6xGBJAjhok',
  authDomain: 'autovizo.firebaseapp.com',
  projectId: 'autovizo',
  storageBucket: 'autovizo.appspot.com',
  messagingSenderId: '636844808064',
  appId: '1:636844808064:web:91de3c01cd8ed1507fd365',
  measurementId: 'G-GN4WT7VM05',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Initialize Firestore
const db = getFirestore(app)

export default db
